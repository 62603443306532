body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*:focus{
  box-shadow: none!important;
}
#dateid{
  text-transform: uppercase;
  color:#AEAEAE;
}
#dateblackid{
  color: #000;
}


.pagination{
  list-style: none;
  display: flex;
  justify-content: center;
  margin-bottom: 2px;
  font-size: 15px;
  gap: 5px;
}

.pagination .page-num{
  padding: 8px 15px;
  cursor: pointer;
  border-radius: 3px;
  font-weight: 400;
  border: 1px solid black;
  width:max-content;
}

.pagination .page-num:hover{
  background-color: #3b5d7c;
  color: white;
}

.pagination .active{
  background-color: #3b5d7c;
}

.pagination  .page-num-disable{
  padding: 8px 15px;
  cursor: pointer;
  border-radius: 3px;
  font-weight: 400;
  border: 1px solid rgba(31, 31, 31, 0.422);
  width:max-content;
  background-color:rgba(31, 31, 31, 0.422) ;
}
.css-7en1lf{
  padding-top: 18.5px !important;
  }

  .openseadragon-container {
    background-color: white !important;
}
[aria-checked="true"] {
  background-color: #F7F6FF !important;
}
[aria-checked="false"] {
  background-color: white !important;
}
[aria-checked="false"]:hover {
  background-color: #F7F6FF !important;
}

.css-1op4uda{
  overflow-y: auto !important;
  max-height: 300px !important;
}
.css-1velgys {
  height: 700px !important;
}
.css-1xeyldg {
  min-height: 82vh !important;
}
.css-3sjfrl {
  min-height: 80vh !important;
}

/* ::-webkit-scrollbar {
  display: none !important;
} */
@media screen and (min-width: 1920px) {
  .css-1velgys {
    min-height: 898px !important;
  }
  .css-1xeyldg {
    min-height: 85vh !important;
  }
  .css-1p0vzax {
    min-height: 75vh !important;
  }
  .css-3sjfrl {
    min-height: 83vh !important;
  }
 
}
@media screen and (min-width: 1880px) {
  .css-1velgys {
    height: 800px !important;
  }
  .css-1xeyldg {
    min-height: 83vh !important;
  }
  .css-1p0vzax {
    min-height: 75vh !important;
  }
  .css-3sjfrl {
    min-height: 88vh !important;
  }
 
}



